<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.informations_candidat')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <!-- <base-input-text v-bind="formInputProps('label')" :color="color"
                v-model="formData.label" ref="label" /> -->
              <base-input-text class="q-px-sm q-pb-none row" v-bind="formInputProps('nom')" :color="color"
                v-model="formData.nom" ref="label" />
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_nomination')" :color="color"
                  v-model="formData.date_nomination" ref="label" />
                <base-input-select class="col q-pa-none q-mt-none" v-bind="formInputProps('type')" :color="color"
                  v-model="formData.type" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" placeholder="Renseigner" v-bind="formInputProps('date_fin_periode_probatoire')" :color="color"
                  v-model="formData.date_fin_periode_probatoire" ref="label" />
                <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_signature_traite_nomination')" :color="color"
                  v-model="formData.date_signature_traite_nomination" ref="label" />
              </div>
              <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.motif_ko.label')}}</q-item-label>
              <base-input-text class="q-px-sm row" placeholder="" label="" v-bind="formInputProps('motif_ko')" :color="color"
                v-model="formData.motif_ko" ref="label" />
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col" placeholder="Renseigner" v-bind="formInputProps('montant_cheque_apport')" :color="color"
                  v-model="formData.montant_cheque_apport" ref="label" />
                <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_reception_cheque_apport')" :color="color"
                  v-model="formData.date_reception_cheque_apport" ref="label" />
              </div>
              <div class="col q-my-none">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.conformite_lettre_agrement.label')}}</q-item-label>
                <q-toggle class="" v-model="formData['conformite_lettre_d’agrement']" ref="is_red"
                  :color="color"
                />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_regime_RC_pro')" :color="color"
                  v-model="formData.date_reception_regime_RC_pro" ref="label" />
                <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_adhesion_prevoyance_sante')" :color="color"
                  v-model="formData.date_adhesion_prevoyance_sante" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_communication_adresse_fiscale')" :color="color"
                  v-model="formData.date_communication_adresse_fiscale" ref="label" />
                <base-input-text class="col" placeholder="Remplir" v-bind="formInputProps('numero_de_SIREN')" :color="color"
                  v-model="formData.numero_de_SIREN" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="q-gutter-sm justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_declaration_CAVAMAC')" :color="color"
                  v-model="formData.date_declaration_CAVAMAC" ref="label" />
                <base-input-text class="col" placeholder="Remplir" v-bind="formInputProps('matricule_CAVAMAC')" :color="color"
                  v-model="formData.matricule_CAVAMAC" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_demande_ORIAS')" :color="color"
                  v-model="formData.date_demande_ORIAS" ref="label" />
                <base-input-text class="col" placeholder="Remplir" v-bind="formInputProps('numero_ORIAS')" :color="color"
                  v-model="formData.numero_ORIAS" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_reception_RIB')" :color="color"
                  v-model="formData.date_reception_RIB" ref="label" />
                <base-input-date class="col" placeholder="Remplir" v-bind="formInputProps('date_reception_formulaire_CBC')" :color="color"
                  v-model="formData.date_reception_formulaire_CBC" ref="label" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-select :options="booleanOption" class="col" v-bind="formInputProps('lettre_ATGA_affectation_patrimoniale')" :color="color"
                    v-model="formData.lettre_ATGA_affectation_patrimoniale" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div>
        <h6>{{$t('processes.links')}}</h6>
        <div class="row q-gutter-md ">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="menuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_de_periode_probatoire')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row q-gutter-sm">
          <div v-if="relatedPeriodeProbatoire.length" class="column col col-6 q-col-gutter-md q-pt-sm">
            <div class="row" v-for="pProbatoire in relatedPeriodeProbatoire" :key="pProbatoire.id">
              <base-card @menuClick="menuClick" class="col-12">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label class="q-mb-md"><router-link :to="{ name: 'suivi-de-periode-probatoire-form', params: { id: pProbatoire.id } }"><strong>{{$t('processes.suivi_de_periode_probatoire')}}</strong></router-link></q-item-label>
              </base-card>
            </div>
          </div>
          <div class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_suivi_de_periode_probatoire')" :color="color" @click="createSuiviPeriodeProbatoire" />
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.objectifs')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <div class="q-mb-md">
            <q-card v-for="objectif in objectifs.filter(obj => obj.type_objectif)" :key="objectif.id" flat bordered class="q-pa-sm q-mb-md">
              <q-card-section  class="justify-center q-gutter-sm">
                <div class="row q-gutter-sm">
                  <base-input-text class="col" :readonly="true" :label="$t('processes.fields.millesime_objectif.label')" :color="color"
                    :value="objectif.type_objectif.millesime" />
                  <base-input-text class="col" :readonly="true" :label="$t('processes.fields.type_objectif_par_millesime.label')" :color="color"
                    :value="objectif.type_objectif.label" />
                  <base-input-text class="col" :readonly="true" :label="$t('processes.fields.valeur.label')" :color="color"
                    :value="objectif.valeur" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center q-gutter-sm">
              <div class="row q-gutter-sm">
                <base-input-select class="col q-pa-none q-mt-none" :label="$t('processes.fields.millesime_objectif.label')" :options="millesimesInTypeObjectifs" :color="color"
                  v-model="objectif.selectedMillesime" />
                <base-input-select class="col q-pa-none q-mt-none" :label="$t('processes.fields.type_objectif_par_millesime.label')" :options="typObjectifsWithSelectedMillesime" :color="color"
                  v-model="objectif.typeObjectif" />
                <base-input-text class="col" :label="$t('processes.fields.valeur.label')" :color="color"
                  v-model="objectif.valeur" />
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" :disabled="!isUpdate" @click="addObjectif()">{{$t('processes.add')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.portefeuillesConfies')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div class="column q-col-gutter-md full-width">
            <q-table flat wrap-cells v-if="currentNominationProcess"
              :data="ptfsDatas"
              :columns="ptfsColumns"
              row-key="id"
              :loading="loading"
              binary-state-sort>
              <q-tr slot="body" slot-scope="props" :props="props"
                @click.native="$router.push({ name: 'portefeuille-confie-fiche', params: { id: props.row.id } })"
                class="cursor-pointer">
                <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
                  <div v-if="col.value" v-html="col.value" />
                </q-td>
              </q-tr>
            </q-table>
            <div class="row justify-end">
              <div class="col col-5 q-py-sm q-px-md q-gutter-sm">
                <q-btn class="full-width justify-between" color="process" size="sm" :disabled="!isUpdate" :to="{ name: 'suivi-de-nomination-form-portefeuille-confie', params: { processId: this.$route.params.id } }" icon-right="add_circle">{{$t('processes.add_pftConfie')}}</q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess" :disabled="!isUpdate">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'

export default {
  mixins: [BaseForm],
  components: { },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      relatedSujets: [],
      relatedLinks: [],
      relatedPeriodeProbatoire: [],
      isUpdate: this.$route.params.id,
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      },

      // For objectifs
      objectifs: [],
      objectif: {},

      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentNominationProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions',
      typeObjectifs: 'processes/getProcesses'
    }),

    millesimesInTypeObjectifs () {
      return this.typeObjectifs.map(({ millesime }) => millesime).reduce((accumulateur, millesime) => {
        if (millesime && !accumulateur.some(element => element.value === millesime)) {
          accumulateur.push({
            label: millesime,
            value: millesime
          })
        }
        return accumulateur
      }, [])
    },

    typObjectifsWithSelectedMillesime () {
      return this.objectif.selectedMillesime ? this.typeObjectifs.filter(typeObjectif => typeObjectif.millesime === this.objectif.selectedMillesime.value).map(typeObjectif => ({
        label: typeObjectif.label,
        value: typeObjectif.id
      })) : []
    },

    label () {
      return !this.$route.params.id ? this.$t('instances.add_instance') : this.$t('instances.edit_instance')
    },

    ptfsDatas () {
      if (!this.currentNominationProcess || !this.currentNominationProcess.portefeuille_confies.length) return []
      return this.currentNominationProcess.portefeuille_confies
    },

    ptfsColumns () {
      if (!this.currentNominationProcess || !this.currentNominationProcess.portefeuille_confies.length) return []
      else {
        const fields = ['wallets', 'taux_detention', 'date_nomination', 'statut']

        return fields.map(key => {
          let label = this.$t(`processes.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => {
            console.log(val)
            return val
          }

          if (key === 'wallets') {
            label = this.$t(`processes.fields.nomPtfConfie.label`)

            formatFn = val => { console.log(val); return val.map(wallet => wallet.NOMPTF).join(', ') }
          } else if (key === 'statut') {
            formatFn = val => {
              const status = {
                BROUILLON: 'Brouillon',
                EN_COURS: 'En cours',
                SHORTLIST: 'Shortlist',
                RETENU: 'Retenu',
                TERMINE: 'Terminé',
                ABANDONNE: 'Abandonné',
                CANDIDAT: 'Candidat'
              }
              return status[val]
            }
            align = 'center'
          } else if (key === 'date_nomination') {
            formatFn = val => this.$formatDate(val)
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: false,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    relatedLinks (newVal, oldVal) {
      this.formData.liens = JSON.stringify(newVal.length > 0 ? newVal.filter(lien => !lien.id).map(({ titre, lien, id }) => ({ titre, lien, id })) : [])
    },
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'nomination'
        if (!newVal || !this.currentNominationProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentNominationProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentNominationProcess[id])
                  : this.currentNominationProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          // Set ptfs data
          let ptfsMapped = this.currentNominationProcess.portefeuille_confies.map(pft => ({
            model_type: 'portefeuilleConfie',
            model_id: pft.id
          }))
          this.formData.related_objects.push(...ptfsMapped)

          // Set process période probatoire
          if (this.currentNominationProcess.periode_probatoires) {
            let pProbatoireMapped = this.currentNominationProcess.periode_probatoires.map(pProbatoire => ({
              model_type: 'periodeProbatoire',
              model_id: pProbatoire.id
            }))
            this.relatedPeriodeProbatoire = this.currentNominationProcess.periode_probatoires
            this.formData.related_objects.push(...pProbatoireMapped)
          }

          // Set objectifs
          this.objectifs = this.currentNominationProcess.objectifs
          let objectifMapped = this.objectifs.map(objectif => ({
            model_type: 'objectif',
            model_id: objectif.id
          }))
          this.formData.related_objects.push(...objectifMapped)

          // Set links
          this.relatedLinks = this.currentNominationProcess.liens ? JSON.parse(this.currentNominationProcess.liens).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
        }
      },
      immediate: true
    }
  },

  created () {
    this.$store.dispatch('processes/fetchProcesses', { typeProcess: 'consoleAdmin/typeObjectif' })
  },
  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_nomination'), to: { name: 'suivi-de-nomination-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-nomination-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_nomination'))
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'nomination' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'nomination')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'nomination')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
    this.$store.commit('processes/setProcesses', [])
  },
  methods: {
    addObjectif () {
      let params = {
        valeur: this.objectif.valeur,
        model_type: 'objectif',
        related_objects: [
          {
            model_type: 'nomination',
            model_id: this.$route.params.id
          },
          {
            model_type: 'typeObjectif',
            model_id: this.objectif.typeObjectif.value
          }
        ]
      }
      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        this.objectifs.push({
          id: data.id,
          valeur: this.objectif.valeur,
          type_objectif: {
            label: this.objectif.typeObjectif.label,
            millesime: this.objectif.selectedMillesime.label
          }
        })
        this.objectif = {}
        this.formData.related_objects.push({
          model_type: 'objectif',
          model_id: data.id
        })
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    createSuiviPeriodeProbatoire () {
      let currentNomination = { model_type: 'nomination', model_id: this.$route.params.id }
      this.$store.dispatch('processes/saveProcesses', { model_type: 'periodeProbatoire', date_nomination: this.formData.date_nomination, date_fin: this.formData.date_fin_periode_probatoire, statut: 'BROUILLON', related_objects: [currentNomination] }).then(data => {
        let pProbatoireMapped = {
          model_type: 'periodeProbatoire',
          model_id: data.id
        }

        this.relatedPeriodeProbatoire.push({
          id: data.id
        })

        if (!this.formData.related_objects) this.formData.related_objects = []
        // Add in form data
        this.formData.related_objects.push(pProbatoireMapped)
      })
    },
    menuClick ({ name, params }) {
      if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      }
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        console.log(this.relatedLinks)
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
        console.log('ato', tempId)
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-de-nomination-form', params: { id } })
    },
    copyAnnonce () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    onSujetSelect (sujet) {
      this.relatedSujets.push({ model_type: 'sujet', model_id: sujet.id, ...sujet })
      this.isFormOpen = false
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'nomination', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add_process_on_required_label
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0
    top 7px
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
